define("ember-intl/index", ["exports", "ember-intl/services/intl", "ember-intl/macros"], function (_exports, _intl, _macros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    translationMacro: true,
    Service: true
  };
  _exports.translationMacro = translationMacro;
  Object.defineProperty(_exports, "Service", {
    enumerable: true,
    get: function () {
      return _intl.default;
    }
  });
  Object.keys(_macros).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _macros[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _macros[key];
      }
    });
  });

  function translationMacro(...args) {
    (false && !(false) && Ember.deprecate(`ember-intl: 'translationMacro' was renamed to just 't'. Please update the import statement.`, false, {
      id: 'ember-intl.translationMacro',
      until: '5.0.0'
    }));
    return (0, _macros.t)(...args);
  }
});