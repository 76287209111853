define("tracked-built-ins/-private/array", ["exports", "tracked-maps-and-sets/-private/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  // Unfortunately, TypeScript's ability to do inference *or* type-checking in a
  // `Proxy`'s body is very limited, so we have to use a number of casts `as any`
  // to make the internal accesses work. The type safety of these is guaranteed at
  // the *call site* instead of within the body: you cannot do `Array.blah` in TS,
  // and it will blow up in JS in exactly the same way, so it is safe to assume
  // that properties within the getter have the correct type in TS.
  const ARRAY_GETTER_METHODS = new Set([Symbol.iterator, 'concat', 'entries', 'every', 'fill', 'filter', 'find', 'findIndex', 'flat', 'flatMap', 'forEach', 'includes', 'indexOf', 'join', 'keys', 'lastIndexOf', 'map', 'reduce', 'reduceRight', 'slice', 'some', 'values']);

  function convertToInt(prop) {
    if (typeof prop === 'symbol') return null;
    const num = Number(prop);
    if (isNaN(num)) return null;
    return num % 1 === 0 ? num : null;
  }

  function createArrayProxy(arr) {
    let indexTags = [];
    let boundFns = new Map();
    return new Proxy(arr, {
      get(target, prop, receiver) {
        let index = convertToInt(prop);

        if (index !== null) {
          let tag = indexTags[index];

          if (tag === undefined) {
            tag = indexTags[index] = (0, _util.createTag)();
          }

          (0, _util.consumeTag)(tag);
          (0, _util.consumeCollection)(receiver);
          return target[index];
        } else if (prop === 'length') {
          (0, _util.consumeCollection)(receiver);
        } else if (ARRAY_GETTER_METHODS.has(prop)) {
          let fn = boundFns.get(prop);

          if (fn === undefined) {
            fn = (...args) => {
              (0, _util.consumeCollection)(receiver);
              return target[prop](...args);
            };

            boundFns.set(prop, fn);
          }

          return fn;
        }

        return target[prop];
      },

      set(target, prop, value, receiver) {
        target[prop] = value;
        let index = convertToInt(prop);

        if (index !== null) {
          let tag = indexTags[index];

          if (tag !== undefined) {
            (0, _util.dirtyTag)(tag);
          }

          (0, _util.dirtyCollection)(receiver);
        } else if (prop === 'length') {
          (0, _util.dirtyCollection)(receiver);
        }

        return true;
      },

      getPrototypeOf() {
        return TrackedArray.prototype;
      }

    });
  }

  class TrackedArray {
    /**
     * Creates an array from an iterable object.
     * @param iterable An iterable object to convert to an array.
     */

    /**
     * Creates an array from an iterable object.
     * @param iterable An iterable object to convert to an array.
     * @param mapfn A mapping function to call on every element of the array.
     * @param thisArg Value of 'this' used to invoke the mapfn.
     */
    static from(iterable, mapfn, thisArg) {
      return mapfn ? createArrayProxy(Array.from(iterable, mapfn, thisArg)) : createArrayProxy(Array.from(iterable));
    }

    static of(...arr) {
      return createArrayProxy(arr);
    }

    constructor(arr = []) {
      return createArrayProxy(arr.slice());
    }

  } // This rule is correctly in the general case, but it doesn't understand
  // declaration merging, which is how we're using the interface here. This
  // declaration says that `TrackedArray` acts just like `Array<T>`, but also has
  // the properties declared via the `class` declaration above -- but without the
  // cost of a subclass, which is much slower that the proxied array behavior.
  // That is: a `TrackedArray` *is* an `Array`, just with a proxy in front of
  // accessors and setters, rather than a subclass of an `Array` which would be
  // de-optimized by the browsers.
  //
  // eslint-disable-next-line @typescript-eslint/no-empty-interface


  var _default = TrackedArray; // Ensure instanceof works correctly

  _exports.default = _default;
  Object.setPrototypeOf(TrackedArray.prototype, Array.prototype);
});