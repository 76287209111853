define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X8eviIXp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"class\",\"x-toggle\"],[11,\"checked\",[23,0,[\"toggled\"]]],[11,\"disabled\",[23,0,[\"disabled\"]]],[11,\"id\",[23,0,[\"forId\"]]],[11,\"name\",[23,0,[\"name\"]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],[23,0,[\"sendToggle\"]]],[[\"value\"],[\"target.checked\"]]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\\n\"],[7,\"label\",true],[11,\"for\",[23,0,[\"effectiveForId\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"id\",[29,[\"x-toggle-visual-\",[23,0,[\"forId\"]]]]],[10,\"role\",\"checkbox\"],[11,\"class\",[29,[\"x-toggle-btn \",[23,0,[\"themeClass\"]],\" \",[23,0,[\"size\"]],[28,\"if\",[[23,0,[\"disabled\"]],\" x-toggle-disabled\"],null]]]],[11,\"aria-owns\",[23,0,[\"forId\"]]],[11,\"aria-checked\",[23,0,[\"toggled\"]]],[11,\"data-tg-on\",[23,0,[\"onLabel\"]]],[11,\"data-tg-off\",[23,0,[\"offLabel\"]]],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});