define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "banner": "__2e204",
    "boutique-product-activity": "__484a4",
    "boutique-reservation-item/boutique-reservation-item-product/edit-reservations-status-modal": "__16bb5",
    "checkbox": "__6ef9f",
    "curate-order-form/add-reservation-modal": "__33574",
    "feed-form": "__bad6d",
    "feed-sync-advanced-settings": "__8354a",
    "feed-sync-behaviour-explainer": "__5fd07",
    "feed-sync-permanent-rule-settings": "__f78c5",
    "form-images": "__ec4ef",
    "frame-form/common": "__118fa",
    "frame-form/lifestyle": "__aa2ce",
    "frame-form/product": "__7d522",
    "frame-form/spotify": "__c5d00",
    "frame-form": "__c29a2",
    "frame-form/tags": "__45db2"
  };
  _exports.default = _default;
});