define("ember-changeset/utils/object-without", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = objectWithout;
  const keys = Object.keys;


  /**
   * Merges all sources together, excluding keys in excludedKeys.
   *
   * @param  {Array[String]}    excludedKeys
   * @param  {...Object}        sources
   *
   * @return {Object}
   */
  function objectWithout(excludedKeys /*: Array<string> */
  , ...sources /*: Array<Object> */
  ) /*: Object */{
    return sources.reduce((acc, source) => {
      keys(source).filter(key => excludedKeys.indexOf(key) === -1 || !source.hasOwnProperty(key)).forEach(key => acc[key] = source[key]);
      return acc;
    }, {});
  }
});